<template>
  <div class="home">
    <div class="home_title">
      <span>当前位置：首页</span>
      <span>当前时间：{{ newDate }}</span>
    </div>
    <el-card v-if="roles === 'r1' || roles === 'r0'">
      <div style="color: #aaaaaa;font-size: 14px;text-align: left;">员工数据以员工注册为基础进行统计，合同数据以合同发起为基础进行统计，支付数据以支付成功为基础进行统计</div>
      <div class="stafDrawer_demo">
        <div style="text-align:left;font-size:14px">
          <span>数据总量</span>
        </div>
        <div class="first_one">
          <div class="first_demo"
               style="background:#effdda;width:20%">
            <div class="first_demo_left"
                 style="width: 100%;height: 170px;background:none">
              <div style="margin:60px 30px">
                <span style="font-weight:700;font-size:22px;">
                  {{
                  platformDetail.enterpriseNum
                  }}
                </span>
                <br />
                <span>企业数量</span>
                <br />
              </div>
            </div>
          </div>

          <div class="first_demo"
               style="width:20%">
            <div style="width: 100%;  height: 170px; color: #3a3a3a;">
              <div style="padding:20px 20px 0 20px;background: #d9d9ff;"
                   @click="getUserMore">
                <span style="font-weight:700;font-size:16px;">{{ platformDetail.regNum }}</span>
                <br />
                <span>注册人数</span>
                <br />
                <div style="display: flex;align-items: center;justify-content: space-between;font-size:12px;color: #ec808d;padding:10px 10px 10px 10px">
                  <span style="width:50%;text-align: center;border-right:1px solid #d7d7d7;padding:3px">
                    今+{{
                    platformDetail.regNum_today
                    }}
                  </span>
                  <span style="width:50%;text-align: center;padding:3px">昨+{{ platformDetail.regNum_yesterday }}</span>
                </div>
              </div>
              <div class="demo_isone">
                <div class="isone_div">
                  <span style="font-size:14px">{{ platformDetail.authNum }}</span>
                  <br />
                  <span style="font-size:12px">认证</span>
                </div>
                <div class="isone_div">
                  <span style="font-size:14px">{{ platformDetail.openNum }}</span>
                  <br />
                  <span style="font-size:12px">开户</span>
                </div>
                <div class="isone_div">
                  <span style="font-size:14px">{{ platformDetail.activeNum }}</span>
                  <br />
                  <span style="font-size:12px">已激活</span>
                </div>
                <div class="isone_div">
                  <span style="font-size:14px">{{ platformDetail.upNum }}</span>
                  <br />
                  <span style="font-size:12px">二升一</span>
                </div>
              </div>
            </div>
          </div>
          <allUser :userDrawer.sync="userDrawer"
                   v-on:close="userClose"></allUser>
          <div class="first_demo"
               style="width:20%">
            <div style="width: 100%;  height: 170px; color: #3a3a3a;">
              <div style="padding:20px 20px 0 20px;background: #d9f2fd;">
                <span style="font-weight:700;font-size:16px;">{{ platformDetail.constractNum }}</span>
                <br />
                <span>创建合同份数</span>
                <br />
                <div style="display: flex;align-items: center;justify-content: space-between;font-size:12px;color: #ec808d;padding:10px 10px 10px 10px">
                  <span style="width:50%;text-align: center;border-right:1px solid #d7d7d7;padding:3px">
                    今+{{
                    platformDetail.constractNum_today
                    }}
                  </span>
                  <span style="width:50%;text-align: center;padding:3px">
                    昨+{{ platformDetail.constractNum_yesterday
                    }}
                  </span>
                </div>
              </div>
              <div class="demo_isone">
                <div class="isone_div">
                  <span style="font-size:14px">{{ platformDetail.constractNum_finished }}</span>
                  <br />
                  <span style="font-size:12px">签署完成</span>
                </div>
                <div class="isone_div">
                  <span style="font-size:14px">{{ platformDetail.constractNum_unFinished }}</span>
                  <br />
                  <span style="font-size:12px">待签署</span>
                </div>
              </div>
            </div>
          </div>
          <div class="first_demo"
               style="width:20%">
            <div style="width: 100%;  height: 170px; color: #3a3a3a;">
              <div style="padding:20px 20px 0 20px;background: #fef0de;">
                <span style="font-weight:700;font-size:16px;">￥{{ platformDetail.paySum }}</span>
                <br />
                <span>支付金额</span>
                <br />
                <div style="display: flex;align-items: center;justify-content: space-between;font-size:12px;color: #ec808d;padding:10px 10px 10px 10px">
                  <span style="width:50%;text-align: center;border-right:1px solid #d7d7d7;padding:3px">
                    今+￥{{
                    platformDetail.paySum_today
                    }}
                  </span>
                  <span style="width:50%;text-align: center;padding:3px">昨+￥{{ platformDetail.paySum_yesterday }}</span>
                </div>
              </div>
              <div class="demo_isone">
                <div class="isone_div">
                  <span style="font-size:14px">{{ platformDetail.projectNum }}</span>
                  <br />
                  <span style="font-size:12px">项目个数</span>
                </div>
                <div class="isone_div">
                  <span style="font-size:14px">{{ platformDetail.payNum }}</span>
                  <br />
                  <span style="font-size:12px">支付次数</span>
                </div>
                <div class="isone_div">
                  <span style="font-size:14px">{{ platformDetail.payUserNum }}</span>
                  <br />
                  <span style="font-size:12px">支付人次</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stafDrawer_demo">
        <div style="text-align:left;font-size:14px">
          <span>企业数据</span>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between; ">
          <div style="display: flex;align-items: center;">
            <span>数据时间</span>
            <el-select v-model="dateSelect"
                       placeholder="请选择"
                       size="mini"
                       style="margin-left:10px"
                       @change="dateSelectChange">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
            <div style="margin-left:20px">
              <el-popover placement="top"
                          width="800"
                          v-model="visible">
                <div style="display: flex;align-items: center;padding: 10px;background: #eaf5ff;">
                  <span>选择导出信息</span>
                  <span style="color: #b2aabd;margin-left: 10px;font-size: 12px;"
                        v-if="!newEnterprise.length">请先选择要导出数据的企业</span>
                </div>
                <div style="margin: 10px;display: flex;">
                  <div style="width:20%">
                    <span>员工数据：</span>
                    <el-checkbox-group v-model="userCheckbox">
                      <el-checkbox v-for="item in userCheckboxList"
                                   :label="item.value"
                                   :key="item.value">
                        {{ item.label
                        }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div style="width:20%">
                    <span>合同数据：</span>
                    <el-checkbox-group v-model="contentCheckbox">
                      <el-checkbox v-for="item in contentCheckboxList"
                                   :label="item.value"
                                   :key="item.value">
                        {{
                        item.label
                        }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div style="width:20%">
                    <span>支付数据：</span>
                    <el-checkbox-group v-model="payCheckbox">
                      <el-checkbox v-for="item in payCheckboxList"
                                   :label="item.value"
                                   :key="item.value">
                        {{ item.label
                        }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div style="width:40%">
                    <span>企业列表：</span>
                    <div style="display: flex;align-items: center;justify-content: space-between;"
                         v-for="(item, i) in newEnterprise"
                         :key="item.code">
                      <span style="font-weight: 700;">{{ item.name }}</span>
                      <el-button type="text"
                                 style="color:#d9001b"
                                 @click="removeItem(i)">移除</el-button>
                    </div>
                  </div>
                </div>

                <div style="display: flex;align-items: center;justify-content: space-around; margin: 20px 0">
                  <el-button type="primary"
                             size="mini"
                             :disabled="!newEnterprise.length"
                             @click="exportExcel">确定导出</el-button>
                  <el-button size="mini"
                             @click="visible = false">取消</el-button>
                </div>

                <el-button style="font-size:14px;color:#1989fa"
                           type="text"
                           slot="reference">
                  导出{{
                  options.find(it => it.value === dateSelect).label
                  }}企业数据({{ newEnterprise.length }})
                </el-button>
              </el-popover>
            </div>
          </div>
          <span style="color: #b2aabd;margin-left: 10px;font-size: 12px;">导出数据必须先选择相应的企业</span>
        </div>
        <el-table :data="tableData"
                  style="width: 100%">
          <el-table-column prop="name"
                           label="企业名称"></el-table-column>
          <el-table-column prop="reg"
                           label="注册人数"></el-table-column>
          <el-table-column prop="open"
                           label="开户人数"></el-table-column>
          <el-table-column label="支付总金额 (￥)">
            <template slot-scope="scope">
              <div>{{ scope.row.paysum }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           align="center">
            <template slot-scope="scope">
              <el-button type="text"
                         :disabled="!!newEnterprise.find(it => it.code === scope.row.code)"
                         @click="add(scope.row)">选中导出</el-button>
              <el-button type="text"
                         @click="seeMoer(scope.row)">查看更多</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <span class="clickColor">共{{ total }}条</span>
          <span class="clickColor"
                style="margin:0 10px">每页{{ pageSize }}条</span>
          <el-pagination background
                         :current-page.sync="pageNumber"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :page-size="pageSize"
                         prev-text="上一页"
                         next-text="下一页"
                         layout="prev,pager, next,jumper"
                         :total="total"></el-pagination>
        </div>
      </div>
      <enterpriseDetail :enterpriseDrawer.sync="enterpriseDrawer"
                        :code.sync="code"
                        v-on:close="enterpriseClose"></enterpriseDetail>
    </el-card>
    <el-card v-if=" roles === 'r2_1'">
      <div class="home-header">
        <img src="./tou.png"
             alt />
        <div class="name">欢迎，{{name}}来到{{enterpriseName}}</div>
        <!-- <div>
          <span class="setting" @click="goUser">
            当前用户
            <i class="el-icon-arrow-right"></i>
          </span>
          &nbsp;
          <span class="setting">
            当前企业
            <i class="el-icon-arrow-right"></i>
          </span>
        </div> -->
      </div>
    </el-card>
    <el-card element-loading-background="rgba(0, 0, 0, 0.8)"
             v-else>
      <el-tabs v-model="activeName"
               @tab-click="handleClick">
        <el-tab-pane label="数据概览"
                     name="first">
          <div class="first_one">
            <div class="first_demo">
              <div class="first_demo_left">
                <div style="margin: 25px ;
    text-align: center;
    word-break: keep-all;
    overflow: hidden;">
                  <span style="font-weight:700;font-size:22px">{{ totalOne.reg_user_num }}</span>
                  <br />
                  <span>注册员工人数</span>
                  <br />
                  <span style="font-size:14px;line-height:30px;color:#1989fa"
                        @click="getMore">查看更多></span>
                </div>
              </div>
              <div class="first_demo_right">
                <div>今日新增：{{ totalOne.today_add_num }}人</div>
                <div>已实名认证：{{ totalOne.authed_num }}人</div>
                <div>已开二类户：{{ totalOne.erlihu_num }}人</div>
                <div>二升一申请：{{ totalOne.upNum }}人</div>
              </div>
            </div>
            <div class="first_demo">
              <div class="first_demo_left">
                <div style="margin: 25px;
    text-align: center;
    word-break: keep-all;
    overflow: hidden;">
                  <span style="font-weight:700;font-size:22px">{{ totalOne.contract_all_num }}</span>
                  <br />
                  <span>已创建合同份数</span>
                  <br />
                  <span style="font-size:14px;line-height:30px;color:#1989fa"
                        @click="getContentMore">查看更多></span>
                </div>
              </div>
              <div class="first_demo_right">
                <div>已签署完成：{{ totalOne.contract_Finish_Num }}份</div>
                <div>企业待签署：{{ totalOne.contract_e_unfinish_Num }}份</div>
                <div>员工待签署：{{ totalOne.contract_u_unfinish_Num }}份</div>
              </div>
            </div>
            <div class="first_demo">
              <div class="first_demo_left">
                <div style="margin: 25px ;
    text-align: center;
    word-break: keep-all;
    overflow: hidden;">
                  <span style="font-weight:700;font-size:22px">￥{{ totalOne.pay_all }}</span>
                  <br />
                  <span>累计支付总额</span>
                  <br />
                  <span style="font-size:14px;line-height:30px;color:#1989fa"
                        @click="getPayMore">查看更多></span>
                </div>
              </div>
              <div class="first_demo_right">
                <div>已建项目：{{ totalOne.project_num }}个</div>
                <div>待还借款：￥{{ totalOne.unborrow }}</div>
                <div>累计结转：￥{{ totalOne.carryOver }}</div>
              </div>
            </div>
          </div>
          <div class="first_two">
            <el-card class="box-card">
              <div ref="echartBox"
                   style="height: 600px"></div>
            </el-card>
          </div>
        </el-tab-pane>
        <allStaff :staffDrawer.sync="staffDrawer"
                  v-on:close="staffClose"></allStaff>
        <allContent :contentDrawer.sync="contentDrawer"
                    v-on:close="contentClose"></allContent>
        <allPay :payDrawer.sync="payDrawer"
                v-on:close="payClose"></allPay>
        <el-tab-pane label="服务信息"
                     name="second"
                     style="height: 100%">
          <div class="second_demo">
            <div class="demo_left">实名认证总数：{{ enterpriseInfo.auth_buy_num }}</div>
            <div>可用次数：{{ enterpriseInfo.auth_surplus_num }}</div>
          </div>
          <div class="second_demo">
            <div class="demo_left">发放总数：{{ enterpriseInfo.pay_buy_num }}</div>
            <div>可用次数：{{ enterpriseInfo.pay_surplus_num }}</div>
          </div>
          <div class="second_demo">
            <div class="demo_left">合同签署总数：{{ enterpriseInfo.contract_buy_num }}</div>
            <div>可用份数：{{ enterpriseInfo.contract_surplus_num }}</div>
          </div>
          <div class="second_demo">
            <div class="demo_left">短信发送总数：{{ enterpriseInfo.sms_buy_num }}</div>
            <div>可用条数：{{ enterpriseInfo.sms_surplus_num }}</div>
          </div>
          <div class="demo_buttom">相关服务套餐价格可来电咨询，咨询电话：18049623199 王经理</div>
        </el-tab-pane>
      </el-tabs>
      <!-- <div class="home-header">
        <div style="width:100px; height:100px;border-radius:50%;margin: auto;">
          <img v-if="syleLogo" :src="syleLogo" alt
            style="width:100%; height:100%;border-radius:50%;border:2px solid #999;box-shadow: 3px 2px 2px #ddd;" />
          <img v-else
            style="width:100%; height:100%;border-radius:50%;border:2px solid #999;box-shadow: 3px 2px 2px #ddd;"
            src="../../assets/logo.jpg" alt />
        </div>
        <div class="name">欢迎，{{ name }}-{{ role }}</div>
        <div>
          <span class="setting" v-access data-ctrl="customerUser" @click="goUser">
            当前用户
            <i class="el-icon-arrow-right"></i>
          </span>
          &nbsp;
          <span class="setting" v-prev path="/system/enterprise/detail" v-access data-ctrl="enterpriseInfo">
            当前企业
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>
      </div>-->
    </el-card>

    <!-- <platformHome></platformHome> -->
    <!-- <agentHome v-if="isAgentHome"></agentHome>
    
    <enterpriseHome v-if="isenterpriseHome"></enterpriseHome>-->
  </div>
</template>

<script>
import moment from 'moment';

import * as echarts from 'echarts';
import { api } from '/src/api/base';
const describeEnterprise = api()('enterprise.describe.json');
const describeplatformIndex = api()('dashboard.platform.index.json');
const describeplatformIndex2 = api()('dashboard.platform.index2.json');

import { parseJsonToTable } from '/src/lib/dev';
// 查询用户信息
export const userDescribe = api()('user.describe.json');
export const enterpriseInfo = api()('enterprise.info.json');
export const dashboardEnterpriseInfo = api()('dashboard.enterprise.index.json');
export const dashboardEnterpriseInfoTwo = api()('dashboard.enterprise.index2.json');
export default {
  components: {
    // agentHome: () => import('./agentHome.vue'),
    // platformHome: () => import('./platformHome.vue'),
    // enterpriseHome: () => import('./enterpriseHome.vue'),
    allPay: () => import('./allPay'),
    allStaff: () => import('./allStaff.vue'),
    allContent: () => import('./allContent.vue'),
    allUser: () => import('./allUser.vue'),
    enterpriseDetail: () => import('./enterpriseDetail.vue'),
  },
  data () {
    return {
      name:
        window.localStorage.getItem('name') ||
        window.localStorage.getItem('username') ||
        '游客',
      enterprise: {},
      newEnterprise: [],
      enterpriseName: window.localStorage.getItem('enterpriseName'),
      pageSize: 10,
      pageNumber: 1,
      platformDetail: {},
      total: 0,
      dateSelect: 'week',
      options: [
        {
          label: '今日',
          value: 'today'
        },
        {
          label: '昨日',
          value: 'yesterday'
        },
        {
          label: '近7日',
          value: 'week'
        },
        {
          label: '平台累计总量',
          value: 'all'
        }
      ],
      visible: false,
      userCheckbox: ['reg', 'pingtai', 'shouquan', 'active'],
      userCheckboxList: [{
        value: 'reg',
        label: '注册人数'
      },
      {
        value: 'auth',
        label: '实名认证人数'
      },
      {
        value: 'pingtai',
        label: '平台开户人数'
      },
      {
        value: 'shouquan',
        label: '授权开户人数'
      },
      {
        value: 'active',
        label: '卡激活人数'
      },
      {
        value: 'up',
        label: '二升一申请人数'
      }
      ],
      contentCheckbox: ['ht'],
      contentCheckboxList: [
        {
          value: 'ht',
          label: '创建合同份数'
        },
        {
          value: 'ht_f',
          label: '签署完成份数'
        },
        {
          value: 'ht_uf',
          label: '待签署份数'
        }
      ],
      payCheckbox: ['paysum'],
      payCheckboxList: [
        {
          value: 'paysum',
          label: '支付总金额'
        },
        {
          value: 'projectNum',
          label: '创建项目个数'
        },
        {
          value: 'paycount',
          label: '支付次数'
        },
        {
          value: 'payuser',
          label: '支付人次'
        }
      ],
      tableData: [],
      isAuthenticated: false,
      isAgentHome: false,
      isPlatformHome: false,
      isenterpriseHome: false,
      activeName: 'first',
      role: '',
      syleLogo: '',
      enterpriseInfo: {},
      totalOne: {},
      data: null,
      dataLine: null,
      date: '',
      newDate: '',
      staffDrawer: false,
      contentDrawer: false,
      payDrawer: false,
      userDrawer: false,
      enterpriseDrawer: false,
      roles: '',
      code: ''
    };
  },
  watch: {
    syleLogo (newV, oldV) {
      console.log(oldV, newV);
    },
  },
  async created () {
    var listOld = new Array(moment().diff(moment('2022-3'), 'month') + 1).fill(null).map((n, i) => moment('2022-3').add(i, 'months').format('YYYY-MM'))
    var listNew = []
    listOld.forEach(n => { !listNew.includes(n.split('-')[0]) && listNew.push(n.split('-')[0]); listNew.push(n) });
    const dateList = listNew.reverse().map(it => ({ value: it, label: it.includes('-') ? it.replace('-', '年') + '月' : it + '年' }))

    this.options = [...this.options, ...dateList]

    this.date = moment().format('YYYY')
    this.newDate = moment().format('YYYY年MM月DD日')
    this.roles = window.localStorage.getItem('roles')

    if (window.localStorage.getItem('syleLogo')) {
      this.syleLogo = window.localStorage.getItem('headImage') || window.localStorage.getItem('syleLogo');
    }

    if (window.localStorage.getItem('isEnterpriseAdmin') === 'false' || window.localStorage.getItem('isEnterpriseSuperAdmin') === 'null' || window.localStorage.getItem('isEnterpriseSuperAdmin') === 'true') {

      if (window.localStorage.getItem('roles') === 'r3') {
        this.role = '财务'
      }
      else if (window.localStorage.getItem('roles') === 'r2') {
        this.role = '超级管理员'
      }
      else if (window.localStorage.getItem('roles') === 'r1') {
        this.role = '平台'
      }
      else if (window.localStorage.getItem('roles') === 'r5') {
        this.role = '人力资源'
      }
      else if (window.localStorage.getItem('roles') === 'r6') {
        this.role = '普通员工'
      }
      else if (window.localStorage.getItem('roles') === 'r7') {
        this.role = '测试员'
      }
    }
    else {
      this.role = '企业管理员'
    }
    if (localStorage.getItem('roles') === 'enterprise_admin') {
      this.getEnterprise();
    }
    if (this.roles === 'r1' || this.roles === 'r0') {
      this.loadData()
      this.losdDataTwo()
    }
    if (this.roles === 'r2_1') {
      this.getInfo();
    }
    else {
      this.getInfo();
      this.getEnterpriseInfo()
      this.getNumberTaltol()
      this.getData();
    }
  },

  methods: {
    async dateSelectChange (val) {
      console.log(val);
      this.losdDataTwo()
    },
    async loadData () {
      const data = await describeplatformIndex({

      })

      this.platformDetail = data
    },
    removeItem (i) {
      this.newEnterprise.splice(i, 1)
    },
    async losdDataTwo () {
      let params = {}
      if (this.dateSelect.includes('-')) {
        params = {
          type: 'month',
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          data: this.dateSelect
        }
      }
      else if (this.dateSelect === 'week' || this.dateSelect === 'yesterday' || this.dateSelect === 'today' || this.dateSelect === 'all') {
        params = {
          type: this.dateSelect,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        }
      }

      else {
        params = {
          type: 'year',
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          data: this.dateSelect
        }
      }
      const data = await describeplatformIndex2(params)
      this.tableData = data.list
      this.total = data.total

    },
    async exportExcel () {
      const enterpriseCode = this.newEnterprise.map(it => it.code)
      const queryTag = [...this.payCheckbox, ...this.contentCheckbox, ...this.userCheckbox]
      var params = {}
      if (this.dateSelect.includes('-')) {
        params = {
          type: 'month',
          enterpriseCodes: enterpriseCode,
          queryTag: queryTag,
          data: this.dateSelect
        }
      }
      else if (this.dateSelect === 'week' || this.dateSelect === 'yesterday' || this.dateSelect === 'today' || this.dateSelect === 'all') {
        params = {
          type: this.dateSelect,
          enterpriseCodes: enterpriseCode,
          queryTag: queryTag,
        }

      }
      else {
        params = {
          type: 'year',
          enterpriseCodes: enterpriseCode,
          queryTag: queryTag,
          data: this.dateSelect
        }

      }
      const data = await describeplatformIndex2(params)

      const objKeys = Object.keys(data.list[0])
      const List = [...this.payCheckboxList, ...this.contentCheckboxList, ...this.userCheckboxList]

      var newData = List.filter(it => objKeys.some(ele => ele === it.value))
      const adv = []
      for (let a = 0; a < newData.length; a++) {
        adv[`${newData[a].label}`] = newData[a].value
      }
      console.log(adv, 'List');
      const time = moment().format('YYYY-MM-DD');
      parseJsonToTable(
        `企业数据统计_${time}`,
        {
          '企业名称': 'name',
          ...adv,
        },
        data.list,
        true
      );
      this.visible = false;
    },
    getPayMore () {
      this.payDrawer = true
    },
    getMore () {
      this.staffDrawer = true
    },
    getUserMore () {

      this.userDrawer = true
    },
    seeMoer (row) {

      this.code = row.code
      this.enterpriseDrawer = true
    },
    getContentMore () {
      this.contentDrawer = true
    },
    staffClose (a) {
      this.staffDrawer = a
    },
    payClose (a) {
      this.payDrawer = a
    },
    userClose (a) {
      this.userDrawer = a
    },
    contentClose (a) {
      this.contentDrawer = a
    },
    enterpriseClose (a) {
      this.enterpriseDrawer = a
    },
    async getNumberTaltol () {
      const res = await dashboardEnterpriseInfo({

      });
      this.totalOne = res

    },
    async getData () {
      const res = await dashboardEnterpriseInfoTwo({
        year: ''
      });
      this.data = res;
      this.dataLine = this.data.map((it) => moment(it.month).format('MMMM'));

      this.setOption();

    },
    setOption () {

      var myChart = echarts.init(this.$refs.echartBox);
      window.onresize = function () {
        myChart.resize();
      };
      let serverData = [];
      serverData.push({
        type: 'line',
        smooth: true,
        symbolSize: 8,
        symbol: 'circle',
        data: this.data.map(it => ({ ...it, value: it.money, month: it.month.slice(5, 6) }))
      });
      var option = {
        title: {
          text: `${this.data[0].month.slice(0, 4)}年年度资金支出情况`,
          left: '40%',
          top: '3%',
          textStyle: {
            color: '#000000',
            fontWeight: 'bolder',
            fontSize: 17,
          },
        },
        tooltip: {
          backgroundColor: 'rgba(50,50,50,0.7)',
          textStyle: {
            color: '#fff'
          },
          formatter: function (params) {

            return `<div style="text-align:left;padding:5px">${params[0].data.month}月份<br/>有支出的项目：${params[0].data.project}个<br/>总支出：￥${params[0].data.money}</div>`;
          },
          trigger: 'axis',
        },
        legend: {
          orient: 'horizontal',
          right: '10%',
          data: this.dataLine,
          lineStyle: {
            width: 2,
            height: 'solid',
          },
        },
        xAxis: {
          name: '月份',
          nameTextStyle: {
            color: '#595959',
          },
          axisTick: {
            alignWithLabel: true,
          },
          data: this.dataLine,
        },
        yAxis: {
          name: '元(￥)',
          nameTextStyle: {
            align: 'center',
            color: '#595959',
          },
          // axisLabel: {
          //   formatter: '{value} 万元'
          // },
          type: 'value'

        },
        color: ['#3aa3fc', '#91fc15', '#7f15fc', '#5B8FF9', '#ae570d'],
        series: serverData,
      };
      myChart.setOption(option, true);
    },
    async getEnterpriseInfo () {
      const res = await enterpriseInfo({
        code: window.localStorage.getItem('enterprise'),
      });
      this.enterpriseInfo = res.config

    },
    async getEnterprise () {
      this.enterprise = await describeEnterprise({
        code: window.localStorage.getItem('enterprise'),
      });
      this.name =
        this.enterprise.name ||
        window.localStorage.getItem('name') ||
        window.localStorage.getItem('username');
    },
    async getInfo () {
      const res = await userDescribe({
        code: localStorage.getItem('code'),
      });
      this.isAuthenticated = res.isAuthenticated;
    },
    goUser () {
      this.$router.push({
        path: this.isAuthenticated
          ? '/system/user/detail'
          : '/system/enterprise/detail',
      });
    },
    goRoleManagement () {
      this.$router.push({
        path: '/system/roleManagement/List',
      });
    },
    goResourceManagement () {
      this.$router.push({
        path: '/system/resourceManagement/List',
      });
    },
    handleClick (tab, event) {
      console.log(tab, event);
    },
    handleSizeChange (val) {
      this.pageSize = val;

      this.losdDataTwo();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.losdDataTwo();
    },
    add (row) {
      this.newEnterprise.push(row)
    }
  },
};
</script>

<style lang="scss" >
.stafDrawer_demo {
  padding: 20px 0;
}

.el-table thead.is-group th.el-table__cell {
  background: #4977fc;
}

.demo_isone {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .isone_div {
    text-align: center;
  }
}

/* .home {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  background: rgb(255, 255, 255);
  padding: 20px 40px 40px;
  border: 1px solid rgb(240, 240, 240);
} */
.home-header {
  margin: 60px 0 20px 0;
}

.home_title {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.first_one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  .first_demo {
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #d7d7d7;
    .first_demo_left {
      height: 130px;
      background: #eaf5ff;
      color: #3a3a3a;
    }

    .first_demo_right {
      text-align: left;
      color: #999;

      div {
        margin: 5px 10px;
        font-size: 14px;
      }
    }
  }
}

.second_demo {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 3px dashed #eee;

  .demo_left {
    display: flex;
    width: 450px;
  }
}

.name {
  font-size: 34px;
  font-weight: bold;
}

.setting {
  color: #306ddf;
  font-size: 15px;
  font-weight: bold;
  margin-top: 6px;
  cursor: pointer;
  padding-right: 20px;
}

// .item {
//   margin: 25px 35px;

//   text-align: center;
//   word-break: keep-all;
//   overflow: hidden;
// }

.title {
  color: #3a3a3a;
  margin-top: 6px;
}

.icon-img {
  box-shadow: 0 0 15px #cecece;
  border-radius: 18px;
  cursor: pointer;
}

.demo_buttom {
  text-align: left;
  margin-top: 50px;
  color: #aaaaaa;
  font-size: 14px;
}
</style>
